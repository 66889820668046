<template>
  <div class="max-box">
    <!-- 头部搜索、操作 -->
    <div class="header-box">
      <div class="header-left">
        <span>个人信息</span>
      </div>
    </div>
    <!-- 内容区 -->
    <div class="content-box">
      <div class="user-msg-box">
        <div class="left-box">
          <div class="title-box">头像</div>
          <div class="second-box">
            <template v-if="userMsg?.headImgUrl">
              <img :src="userMsg.headImgUrl" alt="" />
            </template>

            <span v-else>暂无头像</span>
          </div>
        </div>
        <div class="right-box" @click="openModal(1)">修改</div>
      </div>
      <div class="user-msg-box">
        <div class="left-box">
          <div class="title-box padtop">姓名</div>
          <div class="second-box">{{ userMsg?.name || "--" }}</div>
        </div>
        <div class="right-box" @click="openModal(2)">修改</div>
      </div>
      <div class="user-msg-box">
        <div class="left-box">
          <div class="title-box padtop">邮箱</div>
          <div class="second-box">{{ userMsg?.email || "--" }}</div>
        </div>
        <div class="right-box" @click="openModal(3)">修改</div>
      </div>
      <div class="user-msg-box">
        <div class="left-box">
          <div class="title-box padtop">手机号</div>
          <div class="second-box">{{ userMsg?.telPhone || "--" }}</div>
        </div>
        <div class="right-box" @click="openModal(4)">修改</div>
      </div>
      <div class="user-msg-box">
        <div class="left-box">
          <div class="title-box padtop">登录密码</div>
          <div class="second-box">******</div>
        </div>
        <div class="right-box" @click="openModal(5)">修改</div>
      </div>
    </div>
    <a-modal
      :title="`修改用户${getTitle}`"
      :visible="modalVisible"
      @ok="modalOk"
      @cancel="modalCancel"
    >
      <a-form-model ref="ruleForm" :model="form" :rules="rules">
        <div class="upload-box" v-if="activeIndex == 1">
          <a-upload
            list-type="picture-card"
            class="avatar-uploader"
            :show-upload-list="false"
            :before-upload="beforeUpload"
          >
            <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
            <div v-else>
              <a-icon type="plus" />
            </div>
          </a-upload>
        </div>
        <div v-if="activeIndex == 2">
          <a-form-model-item label="姓名" prop="name">
            <a-input v-model="form.name"></a-input>
          </a-form-model-item>
        </div>
        <div v-if="activeIndex == 3">
          <a-form-model-item label="邮箱" prop="email">
            <a-input v-model="form.email"></a-input>
          </a-form-model-item>
        </div>
        <div v-if="activeIndex == 4">
          <a-form-model-item label="手机号" prop="telPhone">
            <a-input v-model="form.telPhone"></a-input>
          </a-form-model-item>
        </div>

        <div v-if="activeIndex == 5">
          <a-form-model-item label="旧密码" prop="oldPassword">
            <a-input v-model="form.oldPassword"></a-input>
          </a-form-model-item>
          <a-form-model-item label="新密码" prop="password">
            <a-input v-model="form.password"></a-input>
          </a-form-model-item>
          <a-form-model-item label="再次输入" prop="repeatPassword">
            <a-input v-model="form.repeatPassword"></a-input>
          </a-form-model-item>
        </div>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import {
  getCurrentUser,
  userUploadIcon,
  updateSingleUser,
  setPassword,
} from "@/api/user.js";
export default {
  data() {
    return {
      userMsg: null,
      modalVisible: false,
      fileData: null,
      imageUrl: "",
      activeIndex: "",
      form: {
        name: "",
        email: "",
        telPhone: "",
        oldPassword: "",
        password: "",
        repeatPassword: "",
      },
      rules: {
        name: [{ required: true, message: "请填写名字", trigger: "blur" }],
        email: [{ required: true, message: "请填写邮箱", trigger: "blur" }],
        telPhone: [
          { required: true, message: "请填写手机号", trigger: "blur" },
        ],
        oldPassword: [
          { required: true, message: "请填写原密码", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请填写新密码", trigger: "blur" },
          { min: 6, message: "密码长度不能低于6位", trigger: "blur" },
        ],
        repeatPassword: [
          { required: true, message: "请再次填写密码", trigger: "blur" },
          { min: 6, message: "密码长度不能低于6位", trigger: "blur" },
        ],
      },
      modalTitle: ["头像", "姓名", "邮箱", "手机号", "登录密码"],
    };
  },
  methods: {
    getUserInfo() {
      getCurrentUser().then((res) => {
        this.userMsg = res.data;
        console.log(res);
      });
    },
    beforeUpload(file) {
      this.fileData = file;
      const reader = new FileReader();
      reader.onload = (event) => {
        this.imageUrl = event.target.result; // 将本地图片的 URL 存储到 imageUrl 中
      };
      reader.readAsDataURL(file);
      return false;
    },
    modalOk() {
      if (this.activeIndex == 1) {
        let requestData = {
          multipartFile: this.fileData,
          id: this.userMsg.id,
        };
        userUploadIcon(requestData).then((res) => {
          this.getUserInfo();
          this.modalVisible = false;
        });
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            if (this.activeIndex == 5) {
              if (this.form.password !== this.form.repeatPassword) {
                return this.$message.warning("两次密码不一致");
              } else {
                setPassword({
                  id: this.userMsg.id,
                  oldPassword: this.form.oldPassword,
                  password: this.form.password,
                }).then((res) => {
                  if (res.code == "000000") {
                    this.$message.success("修改密码成功");
                    this.getUserInfo();
                    this.modalVisible = false;
                  } else {
                    this.$message.error("修改密码失败");
                  }
                });
              }
            } else {
              let requestData = {
                id: this.userMsg.id,
              };
              if (this.activeIndex == 2) {
                requestData.name = this.form.name;
              } else if (this.activeIndex == 3) {
                requestData.email = this.form.email;
              } else if (this.activeIndex == 4) {
                requestData.telPhone = this.form.telPhone;
              }
              updateSingleUser(requestData).then((res) => {
                if (res.code == "000000") {
                  this.$message.success(`修改${this.getTitle}成功`);
                  this.getUserInfo();
                  this.modalVisible = false;
                } else {
                  this.$message.erro(`修改${this.getTitle}失败`);
                }
              });
            }
          }
        });
      }
    },
    modalCancel() {
      this.modalVisible = false;
    },
    openModal(index) {
      this.activeIndex = index;
      this.modalVisible = true;
      if (index == 2) this.form.name = this.userMsg.name;
      if (index == 3) this.form.email = this.userMsg.email;
      if (index == 4) this.form.telPhone = this.userMsg.telPhone;
    },
  },
  computed: {
    getTitle() {
      return this.modalTitle[this.activeIndex - 1];
    },
  },
  mounted() {
    this.getUserInfo();
  },
};
</script>
<style lang="less" scoped>
.max-box {
  width: 100%;
  padding-top: 16px;
  height: 100%;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-right: 20px;
    margin-bottom: 16px;
    .header-left {
      span {
        font-size: 18px;
        color: #252b3a;
      }
    }
  }
  .content-box {
    flex-grow: 1;
    overflow-y: auto;
    background: #fff;
    border: 1px solid rgba(37, 43, 58, 0.1);
    border-radius: 4px;
    padding: 16px;
    .user-msg-box {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-box {
        font-size: 12px;
        color: #00000073;
        .title-box {
          padding-bottom: 8px;
        }
        .padtop {
          padding-top: 16px;
        }
        .second-box {
          padding-bottom: 16px;
          color: #515561;
          img {
            width: 48px;
            height: 48px;
            border-radius: 50%;
          }
        }
      }
      .right-box {
        cursor: pointer;
        font-size: 12px;
        color: #048fff;
        padding-right: 16px;
      }
    }
  }
}
.upload-box {
  width: 100%;
  display: flex;
  justify-content: center;
  /deep/.avatar-uploader {
    width: 128px;
    height: 128px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>